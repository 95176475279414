:root {
  --color-dark: #303841;
  --color-grey: #3a4750;
  --color-red: #d72323;
  --color-bright: #eeeeee;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-family: source-serif-pro, serif;
  font-weight: 900;
  font-size: 5em;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
